import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContainer,[_c(VRow,[_c(VCol,[(_vm.isApplying)?_c(VCard,{staticClass:"mb-2",class:{ 'px-5 py-4 mb-5': _vm.$vuetify.breakpoint.mdAndUp }},[_c(VCardTitle,[_vm._v("Applying new template")]),_c(VCardSubtitle,[_vm._v(" Please wait... "),_c(VProgressLinear,{attrs:{"indeterminate":"","rounded":"","color":"primary"}})],1),_c(VCardText,{staticClass:"px-0"},[_c(VContainer,[_c(VRow,[_c(VCol,{staticClass:"justify-center",attrs:{"cols":"12"}},[_c('a-video-template-card',{attrs:{"template":_vm.selected}})],1)],1)],1)],1)],1):_c(VCard,{staticClass:"mb-2",class:{ 'px-5 py-4 mb-5': _vm.$vuetify.breakpoint.mdAndUp }},[_c(VCardTitle,[_vm._v("Select your template")]),_c(VCardText,{staticClass:"px-0"},[_c(VContainer,[(_vm.isLoading)?_c(VRow,{staticClass:"pt-2"},_vm._l((3),function(i){return _c(VCol,{key:i,attrs:{"cols":"6","md":"4"}},[_c(VSkeletonLoader,{staticClass:"elevation-2",attrs:{"type":"card"}})],1)}),1):_c(VRow,{staticClass:"pt-2"},_vm._l((_vm.templates),function(template){return _c(VCol,{key:template.id,attrs:{"cols":"6","md":"4"}},[_c('a-video-template-card',{attrs:{"template":template},on:{"click":function($event){return _vm.preview(template)}}})],1)}),1)],1)],1)],1)],1)],1),(_vm.previewing)?_c('a-video-template-preview',{attrs:{"template":_vm.previewing},on:{"select":_vm.select}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }