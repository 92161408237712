<template>
    <v-container>
        <v-row>
            <v-col>
                <v-card
                    v-if="isApplying"
                    class="mb-2"
                    :class="{ 'px-5 py-4 mb-5': $vuetify.breakpoint.mdAndUp }"
                >
                    <v-card-title>Applying new template</v-card-title>
                    <v-card-subtitle>
                        Please wait...
                        <v-progress-linear
                            indeterminate
                            rounded
                            color="primary"
                        />
                    </v-card-subtitle>
                    <v-card-text class="px-0">
                        <v-container>
                            <v-row>
                                <v-col cols="12" class="justify-center">
                                    <a-video-template-card
                                        :template="selected"
                                    />
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-card>
                <v-card
                    v-else
                    class="mb-2"
                    :class="{ 'px-5 py-4 mb-5': $vuetify.breakpoint.mdAndUp }"
                >
                    <v-card-title>Select your template</v-card-title>
                    <v-card-text class="px-0">
                        <v-container>
                            <v-row v-if="isLoading" class="pt-2">
                                <v-col v-for="i in 3" :key="i" cols="6" md="4">
                                    <v-skeleton-loader
                                        class="elevation-2"
                                        type="card"
                                    />
                                </v-col>
                            </v-row>
                            <v-row v-else class="pt-2">
                                <v-col
                                    v-for="template in templates"
                                    :key="template.id"
                                    cols="6"
                                    md="4"
                                >
                                    <a-video-template-card
                                        :template="template"
                                        @click="preview(template)"
                                    />
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <a-video-template-preview
            v-if="previewing"
            :template="previewing"
            @select="select"
        />
    </v-container>
</template>

<script lang="ts">
import Component, { mixins } from 'vue-class-component';

import { Endpoint } from '@/mixins';

import {
    AVideoTemplateCard,
    AVideoTemplatePreview
} from '@/components/ATemplateSelector';

import type { SlideshowTemplate } from '@/types/Slideshow';
import type { VideoTemplate } from '@/types/Video';

@Component({
    components: {
        AVideoTemplateCard,
        AVideoTemplatePreview
    }
})
export default class FromTemplate extends mixins(Endpoint) {
    endpoint = '/video_prs/add?announcement_id';

    templates: SlideshowTemplate[] = [];

    previewing: SlideshowTemplate | null = null;

    isApplying = false;

    selected: VideoTemplate | null = null;

    get announcementId() {
        return this.$route.params.announcementId;
    }

    get sourceUrl() {
        return [this.endpoint, this.announcementId].join('=');
    }

    onMounted() {
        if (this.announcementId) {
            this.load();
        }
    }

    onData(data: { templates: SlideshowTemplate[] }) {
        this.templates = data.templates;
    }

    preview(template: SlideshowTemplate) {
        this.previewing = template;

        this.$store.dispatch('modal/open', 'amp-video-template-preview');
    }

    async select(template: VideoTemplate) {
        this.isApplying = true;

        this.selected = template;
        // notify server about new template
        await this.$http.get(
            `/video_prs/edit?announcement_id=${this.announcementId}&template_id=${template.id}`
        );
        // reload entire Amp since Video receives new ID
        this.$emit(
            'reload',
            `/announcements/edit/${this.announcementId}/video`
        );
    }
}
</script>
